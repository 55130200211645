import { cloneDeep, isArray, isNil, isObject, mergeWith } from "lodash";

export const inherit = (question, entities) => {
  const options = question.options
    .map(optionId => entities.questionOption[optionId])
    .map(option => inheritOption(option, entities));

  const parentage = getParentQuestions(question, cloneDeep(entities));
  const result = mergeWith({}, ...parentage, nilMergeDeep);
  result.options = options;

  return result;
};

export const inheritOption = (option, entities) => {
  const parentage = getParentOptions(option, entities);

  return mergeWith({}, ...parentage, nilMergeDeep);
};

function denormalize(originalQuestion, entities) {
  const question = cloneDeep(originalQuestion);
  question.label = entities.localization[question.label];
  question.placeholder = entities.localization[question.placeholder];
  question.infoHover = entities.localization[question.infoHover];

  return question;
}

function denormalizeOption(originalOption, entities) {
  const option = cloneDeep(originalOption);
  option.label = entities.localization[option.label];
  option.remark = entities.localization[option.remark];
  option.hover = entities.localization[option.hover];

  return option;
}

function getParentQuestions(question, entities) {
  const denormalizedQuestion = denormalize(question, entities);
  const parentage = [denormalizedQuestion];
  let parentId = question.parent;

  while (!isNil(parentId)) {
    const parent = denormalize(entities.question[parentId], entities);
    parentage.push(parent);
    parentId = parent.parent;
  }

  return parentage;
}

function getParentOptions(originalOption, entities) {
  const option = denormalizeOption(originalOption, entities);
  const parentage = [option];
  let parentId = option.parent;

  while (!isNil(parentId)) {
    const parent = denormalize(entities.questionOption[parentId], entities);
    parentage.push(parent);
    parentId = parent.parent;
  }

  return parentage;
}

// Note: if you change `_.isNil` to `_.isUndefined`
// then you'd get the `_.defaults()` normal behavior
const nilMerge = (a, b) => (isNil(a) ? b : a);

const nilMergeDeep = (a, b) =>
  isObject(a) && !isArray(a)
    ? // recursively merge objects with nilMergeDeep customizer
      mergeWith({}, a, b, nilMergeDeep)
    : // let's use our default customizer
      nilMerge(a, b);

// defaults not deep with null/undefined
// const result1 = mergeWith({}, o1, o2, o3, nilMerge);

// defaults deep with null/undefined
// const result2 = mergeWith({}, o1, o2, o3, nilMergeDeep);
