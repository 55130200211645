import { isNil } from "lodash";
import crudGetters from "@/store/templates/crud/getters";
import { inherit } from "@/store/questionInheritance";
import {
  createGmapCircle,
  createGmapPolygon,
  mapCoordinateArraysToCoordinateObjects
} from "@/store/geography";

export default {
  ...crudGetters,
  id: state => state.model.id,
  isSnapshot: state => !isNil(state.model.original),
  currentVersionId: state => state.model.original,
  type: state => state.model.type,
  isPersisted: state => !isNil(state.model.id),
  // Type
  isCompanyFilter: (state, getters) => getters.type === "COMPANY",
  isBulditFilter: (state, getters) => getters.type === "BULDIT",
  company: state => state.model.company,
  hasSubFilters: (state, getters) =>
    getters.isBulditFilter && state.model.filters.length > 0,
  hasParentFilters: (state, getters) =>
    getters.isCompanyFilter && state.model.parentFilters.length > 0,
  subFilters: state => state.model.filters,
  parentFilters: state => state.model.parentFilters,
  isTypeValid: (state, getters) =>
    !isNil(state.model.type) &&
    ((getters.isCompanyFilter && !isNil(getters.company)) ||
      (getters.isBulditFilter && getters.subFilters.length > 0)),
  // Categories
  isCategoriesValid: state => state.model.filterCategories.length > 0,
  filterCategories: state => state.model.filterCategories,
  categories: state =>
    state.model.filterCategories.map(filterCategory => filterCategory.category),
  languages: state => state.model.languages,
  // Specifiers
  areAlwaysVisibleQuestionsLoaded: state =>
    state.alwaysVisibleQuestionIds.length > 0,
  alwaysVisibleQuestions: state =>
    state.alwaysVisibleQuestionIds.map(questionId =>
      inherit(state.question[questionId], {
        question: state.question,
        questionOption: state.questionOption,
        localization: state.localization
      })
    ),
  areQuestionsLoadedForCategory: state => categoryId =>
    Object.keys(state.categoryQuestionIds).includes(`${categoryId}`),
  categoryQuestions: state => categoryId =>
    state.categoryQuestionIds[categoryId].map(questionId =>
      inherit(state.question[questionId], {
        question: state.question,
        questionOption: state.questionOption,
        localization: state.localization
      })
    ),
  // Geography
  country: state => state.model.country,
  isEntireCountry: state => state.model.isEntireCountry,
  hasProvinces: state => state.model.provinces.length > 0,
  provinces: state => state.model.provinces,
  hasCities: state => state.model.cities.length > 0,
  cities: state => state.model.cities,
  cityEntities: (state, getters, rootState, rootGetters) =>
    isNil(rootState["city-select"]) ? [] : rootGetters["city-select/items"],
  cityPolygons: (state, getters) => {
    return getters.cityEntities
      .filter(city => city.bounds !== null)
      .map(({ id, bounds }) => ({
        id,
        coordinates: mapCoordinateArraysToCoordinateObjects(bounds)
      }));
  },
  selectedShape: state => state.selectedShape,
  hasIncludedPolygons: state => state.model.includedPolygons.length > 0,
  includedPolygons: state => state.model.includedPolygons,
  includedPolygonGmapObjects: (state, getters) =>
    getters.includedPolygons.map(polygon => createGmapPolygon(polygon)),
  hasIncludedCircles: state => state.model.includedCircles.length > 0,
  includedCircles: state => state.model.includedCircles,
  includedCircleGmapObjects: (state, getters) =>
    getters.includedCircles.map(({ id, center, radius, cityId, city }) => {
      const map = createGmapCircle(id, center, radius);
      if (!isNil(cityId)) {
        map.cityId = cityId;
      }
      if (!isNil(city)) {
        map.city = city;
      }
      return map;
    }),
  hasExcludedPolygons: state => state.model.excludedPolygons.length > 0,
  excludedPolygons: state => state.model.excludedPolygons,
  excludedPolygonGmapObjects: (state, getters) =>
    getters.excludedPolygons.map(polygon => createGmapPolygon(polygon, true)),
  hasExcludedCircles: state => state.model.excludedCircles.length > 0,
  excludedCircles: state => state.model.excludedCircles,
  excludedCircleGmapObjects: (state, getters) =>
    getters.excludedCircles.map(({ id, center, radius, cityId, city }) => {
      const map = createGmapCircle(id, center, radius, true);
      if (!isNil(cityId)) {
        map.cityId = cityId;
      }
      if (!isNil(city)) {
        map.city = city;
      }
      return map;
    }),
  isGeographyValid: (state, getters) =>
    getters.isEntireCountry ||
    getters.hasProvinces ||
    getters.hasCities ||
    getters.hasIncludedPolygons ||
    getters.hasIncludedCircles,
  // Meta
  isActive: state => state.model.isActive,
  name: state => state.model.name,
  apiKey: state => state.model.apiKey,
  priceEuro: state => state.model.priceEuro,
  isAutoclaim: state => state.model.isAutoclaim,
  isAutoDelivery: state => state.model.isAutoDelivery,
  isPriority: state => state.model.isPriority,
  maxWeeklyLeads: state => state.model.maxWeeklyLeads,
  deliveryMethods: state => state.model.deliveryMethods,
  remark: state => state.model.remark,
  logs: state => state.model.logs.map(logId => state.log[logId]),
  pausedFrom: state => {
    return state.model.pausedFrom;
  },
  pausedTill: state => state.model.pausedTill,
  isPaused: state => state.model.isPaused,
  paused: state => state.model.paused,
  companyFilterComment: state => {
    return isNil(state.company[state.model.company])
      ? state.companyFilterComment
      : state.company[state.model.company].filterComment;
  }
};
